<template>
  	<div class="login_page">
		<div class="login-image-view">
			<img src="../assets/img/logo_img1.png">
		</div>
	  	<transition name="form-fade" mode="in-out">
	  		<section class="form_contianer">
			     <div class='titleArea rflex'>
					<img class="logo" src="../assets/logo_small.png" alt="Vknow">
					<span class='title'>麦小阅卷</span>
				</div>
		    	<el-form :model="loginForm" :rules="rules" ref="loginForm" class="loginForm">
					<el-form-item prop="username" class="login-item">
					    <span class="loginTips"><icon-svg icon-class="iconuser" /></span>
						<el-input @keyup.enter.native ="submitForm('loginForm')" size="medium" class="area" type="text" placeholder="用户名" v-model="loginForm.username" ></el-input>
					</el-form-item>
					<el-form-item prop="password" class="login-item">
					    <span class="loginTips"><icon-svg icon-class="iconLock" /></span>
						<el-input @keyup.enter.native ="submitForm('loginForm')" size="medium" class="area" type="password" placeholder="密码" v-model="loginForm.password"></el-input>
					</el-form-item>
					<el-form-item>
				    	<el-button type="primary" v-loading="loading" @click="submitForm('loginForm')" class="submit_btn">登录</el-button>
				  	</el-form-item>
					<div class="tiparea" v-if="myHost=='vknow.caspaper.cn'">
						<img src="../assets/weixin.jpeg" style="width: 50px; height: 50px;" @click="wxShow" />
					</div>
					<div v-else style="height: 60px;"></div>

				</el-form>
	  		</section>
	  	</transition>
		<weiXinLogin v-if="isWxLogin"></weiXinLogin>
      <!--        去掉公众号图片-->
<!--		<div class="mp-qrcode">-->
<!--			<div class="img-qr">-->
<!--				<img src="../assets/mp_qrcode.jpg" />-->

<!--			</div>-->
<!--			<div class="mp-desc">-->
<!--				<span>关注公众号</span>-->
<!--				<br>-->
<!--				<span>及时获取考试信息</span>-->
<!--			</div>-->
<!--		</div>-->
<!--		<footerNav v-if="myHost=='www.51obe.com'"></footerNav>-->
  	</div>
</template>

<script>
	import {mapState, mapActions} from 'vuex'
	import weiXinLogin from "./weiXinLogin";
	import * as simpleApi from "request/simple";
	import footerNav from '@/components/layout/footerNav';
  import {decryptXueZhong} from '@/utils/mUtils'
  import { encrypt, decrypt } from '@/utils/jsencrypt'

	export default {
	    data(){
			return {
				loading: false, isInCefClient: false, isWxLogin: false, myHost: null,
				loginForm: {username: '', password: ''}, openId: null,
				rules: {
					username: [{ required: true, message: '请输入用户名', trigger: 'blur' },
						{ min: 2, max: 30, message: '长度在 2 到 30 个字符', trigger: 'blur' }
			        ],
					password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				}
			}
		},
		components: { weiXinLogin, footerNav },
		computed:{
			...mapState({
				cookieName: state => state.user.userName,
			}),
		},
		mounted(){
	    	console.log("login mount", this.cookieName);
			this.myHost = window.location.host;
			if(this.cookieName && this.cookieName.name){
				this.loginForm.username = this.cookieName.name;
			}
			if(typeof scanManager == 'undefined') {
				this.isInCefClient = false;
			}else{
				this.isInCefClient = true;
			}
			let openId = this.$route.query.oi;
			console.log("openId", openId);
			if(openId && openId != null){
				this.loginByToken(openId);
			}
			let qName = this.$route.query.qn;
			if(qName && qName != null ){
				this.loginForm.username = qName;
			}
      let techCode = this.$route.query.techCode;
      if(techCode && techCode != null ){
        console.log("techCode", techCode)
        let dCode = decryptXueZhong(techCode)
        let encryCode = encrypt(dCode);
        console.log("techCode", techCode, dCode, encryCode)
        this.loginByTeachCode(encryCode)
      }
		},
		methods: {
			...mapActions('user',['setUserInfo', 'setToken', 'setUserName']),
			loginByWechat(){
			},
			showMessage(type,message){
                this.$message({
                    type: type,
                    message: message
                });
            },
			wxShow(){
				this.isWxLogin = !this.isWxLogin;
			},
		    submitForm(loginForm) {
				let that = this;
				this.$refs[loginForm].validate((valid) => {
					if (valid) {
						this.loading = true
						let param = { controllerName: 'public/', methodName: 'login', param: { name: this.loginForm.username, pass: this.loginForm.password}};
						simpleApi.post(param).then(({ result: { code, data}, sysCode}) => {
							console.log('publicController login', data, code)
							if(code == -7 ){
								this.$message({message: '账户状态异常。请联系管理员', type: 'error', duration: 5000});
								return ;
							}
							if(code == -5){
								this.$message({message: '学校状态异常。请联系管理员', type: 'error', duration: 5000});
								return ;
							}
							if(code == -6){
								this.$message({message: '学校许可过期。请联系管理员', type: 'error', duration: 5000});
								return ;
							}
							if(code == 0 ){
								let userType = data.type;
								that.setUserInfo({
									id: data.id, token: data.token, userName: data.name, nickName: data.nickName, role: userType,
									schoolId: data.schoolId,
									licenceId: data.licenceId, licenceKey: data.licenceKey, licenceName: data.licenceName, login: true
								})
								that.setUserName({name: data.name});
								that.setToken(data.token);
								let p = { name: 'root' };
								if(this.isInCefClient){
									p.name = "cefScan";
								}
								if(this.fromUrl){
									p.name = this.fromUrl;
								}
								setTimeout(this.$router.push(p), 500)
							}else{
								this.$message.error("账号和密码错误,清重新再试");
							}
							that.loading = false
						}).catch((error) => {
							that.loading = false;
							console.log("error")
						});

					}
				});
			},
			loginByToken(openId){
				let that = this;
				this.loading = true
				let param = { controllerName: 'public/', methodName: 'loginByToken', param: { openId: openId}};
				simpleApi.post(param).then(({ result: { code, data}, sysCode}) => {
					console.log('loginByToken', data, code)
					if(code == -7 ){ this.$message({message: '账户状态异常。请联系管理员', type: 'error', duration: 5000}); return ; }
					if(code == -5){ this.$message({message: '学校状态异常。请联系管理员', type: 'error', duration: 5000}); return ; }
					if(code == -6){ this.$message({message: '学校许可过期。请联系管理员', type: 'error', duration: 5000}); return ; }
					if(code == 0 ){
						let userType = data.type;
						that.setUserInfo({
							id: data.id, token: data.token, userName: data.name, nickName: data.nickName, role: userType,
							schoolId: data.schoolId,
							licenceId: data.licenceId, licenceKey: data.licenceKey, licenceName: data.licenceName, login: true
						})
						that.setUserName({name: data.name});
						that.setToken(data.token);
						let p = { name: 'root' };
						if(this.isInCefClient){
							p.name = "cefScan";
						}
						if(this.fromUrl){
							p.name = this.fromUrl;
						}
						setTimeout(this.$router.push(p), 500)
					}else{
						this.$message.error("账号和密码错误,清重新再试");
					}
					that.loading = false
				}).catch((error) => {
					that.loading = false;
					console.log("error")
				});
			},
      loginByTeachCode(tCode){
        let that = this;
        this.loading = true
        let param = { controllerName: 'public/', methodName: 'loginByTeachCode', param: { teachCode: tCode}};
        simpleApi.post(param).then(({ result: { code, data}, sysCode}) => {
          console.log('loginByTeachCode', data, code)
          if(code == -7 ){ this.$message({message: '账户状态异常。请联系管理员', type: 'error', duration: 5000}); return ; }
          if(code == -5){ this.$message({message: '学校状态异常。请联系管理员', type: 'error', duration: 5000}); return ; }
          if(code == -6){ this.$message({message: '学校许可过期。请联系管理员', type: 'error', duration: 5000}); return ; }
          if(code == 0 ){
            let userType = data.type;
            that.setUserInfo({
              id: data.id, token: data.token, userName: data.name, nickName: data.nickName, role: userType,
              schoolId: data.schoolId,
              licenceId: data.licenceId, licenceKey: data.licenceKey, licenceName: data.licenceName, login: true
            })
            that.setUserName({name: data.name});
            that.setToken(data.token);
            let p = { name: 'root' };
            if(this.isInCefClient){
              p.name = "cefScan";
            }
            if(this.fromUrl){
              p.name = this.fromUrl;
            }
            setTimeout(this.$router.push(p), 500)
          }else{
            this.$message.error("账号和密码错误,清重新再试");
          }
          that.loading = false
        }).catch((error) => {
          that.loading = false;
          console.log("error")
        });
      },
		}
	}
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">
	.login_page{
		position: absolute;
		width: 100%;
		height: 100%;
		/*background: url(../assets/img/bg.jpg) no-repeat center center;*/
		background-color: #2572C4;
		background-size: 100% 100%;

		.mp-qrcode{
			position: absolute;
			top: 75%;
			left: 0px;
			.img-qr {
				padding: 10px;
				border:1px solid gray;
				border-radius: 5px;
				img {
					width: 100px;
					height: 100px;
				}
			}
			.mp-desc {
				color: white;
				font-size: 14px;
				text-align: center;
			}
		}

	}
	#wxlogin_container{
		position: absolute;
		top: 35%;
		left: 70%;
	}
	.login-image-view{
		position: absolute;
		top: 20%;
		left: 13%;
	}
	.form_contianer{
		position: absolute;
		top: 50%;
        left: 80%;
		transform: translate(-50%,-50%);
		background: #fff;
		width:480px;
		border-radius: 5px;
		padding: 44px 50px 24px 50px;
		text-align: center;
		.titleArea{
			justify-content: center;
   			align-items: center;
			text-transform: uppercase;
			font-size: 24px;
			width: 100%;
			padding-bottom: 44px;
			.logo{
				width: 50px;
    			margin-right: 10px;
				border-radius: 20px;
			}
			.title{
				background-color: transparent;
				border: none;
				color: black;
				width: 120px;
				font-size: 30px;
				i{
					font-size: 30px;
				   color: #3087FF;
				}
			}
		}

		.loginForm{
			.submit_btn{
				width: 100%;
				padding:13px 0;
				font-size: 16px;
			}
			.loginTips{
				position: absolute;
				left: 10px;
				z-index: 20;
				// color: #FF7C1A;
				font-size: 18px;
				top: 50%;
				transform: translateY(-50%);
			}
	    }
	}

	.manage_tip{
		margin-bottom:20px;
		.title{
			font-family: cursive;
			font-weight: bold;
			font-size: 26px;
			color:#fff;
		}
		.logo{
			width:60px;
			height:60px;
		}
	}

	.tiparea{
		text-align:center;
		font-size: 12px;
		color: #4cbb15;
		padding: 10px 0;
		.tip{
			margin-left: 54px;
		}
	}

	.form-fade-enter-active, .form-fade-leave-active {
	  	transition: all 1s;
	}
	.form-fade-enter, .form-fade-leave-active {
	  	transform: translate3d(0, -50px, 0);
	  	opacity: 0;
	}
	.loginForm{
		.area{
			margin-bottom: 14px;
		}
		.el-button--primary{
			background-color:#3087FF;
			border:1px solid #3087FF;
		}
	}
	.sanFangArea{
		border-top: 1px solid #DCDFE6;
		padding: 10px 0;
		display: none;
		.title{
			font-size: 14px;
			color: #8b9196;
			margin-bottom: 10px;
		}
		ul{
			li{
				flex:1;
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				.svg-icon{
					font-size: 24px;
				}
			}
		}
	}
</style>
