<template>
  <div id="wxlogin_container"></div>
</template>

<script>
//import x from ''
import {tools_verify, tools_uri} from '@/common/tools'
export default {
  props: {
    redirect_uri: String
  },
  mounted: () => {
    var obj = new WxLogin({
      id: "wxlogin_container",
      appid: 'wx27bd02a916ff699d',
      scope: "snsapi_login",
      redirect_uri: encodeURIComponent("https://vknow.caspaper.cn/weixin/wxLogin"),
      state: "dl",
      // style: "black",
      //样式 用下面base64转码
      href: 'https://vknow.caspaper.cn/public/wxQrCode.css'
    });
  },
  data() {
    return {};
  },
  computed: {},
  methods: {},
}
</script>
<style lang="scss">


</style>
